body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgb(233, 242, 232);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h4 {
    margin-bottom: 8px;
}

.tryButton {
    width: 300px;
    height: 50px;
    font-size: x-large;
}

.jumbotron {
    background-color: white;
}

.aboutImg {
    width: 285px;
}

.aboutContainer {
    margin: 24px;
    text-align: center;
}

.infoContainer {
    margin: 24px;
}

.navButton {
    margin-top: 4px;
    margin-bottom: 4px;
}

.creditsButton {
    width: 135px;
}

.reservationContainer {
    text-align: center;
}

.reservationContainer form, .reservationContainer .container {
    text-align: left;
    background-color: white;
    border-radius: 4px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.reservationDate, .lastRun, .notify-preference, .notify-preference-label {
    font-size: smaller;
}

.notify-preference {
    max-width: 100%;
    text-overflow: ellipsis;
}

.openReservationModalButton {
    float: right;
}

.reservationActions {
    text-align: right;
    padding-top: 4px;
}

.reservationRow {
    border: 1px solid #e7f2cb;
    border-radius: 4px;
    margin-top: 4px;
    margin-bottom: 4px;
    padding: 8px;
}

.reservationTitle {
    padding-top: 8px;
    padding-bottom: 8px;
    display: inline-block;
}

.reservationRowPlaceholder {
    padding-top: 48px;
    padding-bottom: 48px;
    border: 1px solid #e7f2cb;
    border-radius: 4px;
    text-align: center;
}

.createReservationButton {
    float: right;
}

.creditCost {
    float: right;
    display: inline-block;
    margin: 8px;
}

.campgroundFilter {
    margin-bottom: 8px;
}

.reservations {
    margin-bottom: 16px;
    margin-top: 16px;
    padding-top: 16px;
    padding-bottom: 16px;
}

.campgroundSubtitle {
    margin-left: 24px;
    font-size: smaller;   
}

.campgroundInput {
    border: 1px solid #e7f2cb;
    border-radius: 4px;
    display: inline-block;
    width: 100%;
}

td,th {
    padding-left: 8px;
    padding-right: 8px;
}

div.App {
    text-align: left;
}

input {
    margin-left: 4px;
    margin-right: 4px; 
}

.btn {
    margin-right: 4px;
}

form {
    border-radius: 4px;
    display: inline-block;
    margin: 4px;
    padding: 8px;
}
.googleSignIn {
    margin-right: 8px;
}

.signUpInLabel {
    font-size: smaller;
    margin-left: 16px;
}

.inputLabel {
    width: 80px;
    display: inline-block;
    margin-bottom: 8px;
}

.dateInputLabel {
    width: 100px;
    display: inline-block;
    margin-bottom: 8px;
}

.hr-text {
  line-height: 1em;
  position: relative;
  outline: 0;
  border: 0;
  color: black;
  text-align: center;
  height: 1.5em;
  opacity: .5;
}

.hr-text:before {
    content: '';
    background: linear-gradient(to right, transparent, #818078, transparent);
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    height: 1px;
}

.hr-text:after {
    content: attr(data-content);
    position: relative;
    display: inline-block;
    color: black;

    padding: 0 .5em;
    line-height: 1.5em;
    color: #818078;
    background-color: #fcfcfa;
}