.purchaseButton {
    margin-top: 48px;
}

.nameInput {
    margin-bottom: 24px;
}

.card {
    display: inline-block;
    margin: 8px;
    text-align: left;
}

.card-text {
    height: 100px;
}

.checkout {
    text-align: center;
}

.productConfirmation {
    background: #e7f2cb;
    border-radius: 4px;
    padding: 24px;
    margin-bottom: 24px;
}